import React from 'react';
import { Grid, Button, Box, Typography, Container, useTheme, useMediaQuery, } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import { useEffect } from 'react';

const Cards = () => {
  const { language } = useLanguage();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleApriIlConto = () => {
    if (isMobile) {
      const isAndroid = /android/i.test(navigator.userAgent);
      const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

      if (isAndroid) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
        }, 500);
      } else if (isIOS) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
        }, 500);
      }
    } else {
      window.open('https://my.crhmoney.com/register', '_blank');
    }
  };

  const getCardsImage = () => {
    if (language === 'it') {
      return '/images/Cards1i.svg';
    } else if (language === 'en') {
      return '/images/Cards1e.svg';
    }
    // Default to Italian version if language is not recognized
    return '/images/Cards1i.svg';
  }

  const getCards2Image = () => {
    if (language === 'it') {
      return '/images/Cards2i.svg';
    } else if (language === 'en') {
      return '/images/Cards2e.svg';
    }
    // Default to Italian version if language is not recognized
    return '/images/Cards2i.svg';
  }

  const getCards3Image = () => {
    if (language === 'it') {
      return '/images/Cards3i.svg';
    } else if (language === 'en') {
      return '/images/Cards3e.svg';
    }
    // Default to Italian version if language is not recognized
    return '/images/Cards3i.svg';

  }

  const getCards4Image = () => {
    if (language === 'it') {
      return '/images/Cards4i.svg';
    } else if (language === 'en') {
      return '/images/Cards4e.svg';
    }
    // Default to Italian version if language is not recognized
    return '/images/Cards4i.svg';

  }

  const getCards5Image = () => {
    if (language === 'it') {
      return '/images/Cards5i.svg';
    } else if (language === 'en') {
      return '/images/Cards5e.svg';
    }
    // Default to Italian version if language is not recognized
    return '/images/Cards5i.svg';

  }

  const getCards6Image = () => {
    if (language === 'it') {
      return '/images/Cards6i.svg';
    } else if (language === 'en') {
      return '/images/Cards6e.svg';
    }
    // Default to Italian version if language is not recognized
    return '/images/Cards6i.svg';

  }

  return (
    <>
      <Box
        sx={{
          marginTop: {
            xs: '160px',
            sm: '160px',
            md: '160px',
            lg: '160px',
          },
          paddingTop: {
            xs: '20%',
            sm: '180px',
            md: '80px',
            lg: '80px',
          },
          backgroundImage: {
            xs: `url('/images/Cards6.webp')`,
            sm: `url('/images/Cards5.webp')`,
            md: `url('/images/Cards5.webp')`,
            lg: `url('/images/Cards5.webp')`,
          },
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minHeight: {
            xs: '300px',
            sm: '400px',
            md: '500px',
            lg: '600px',
          },
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              pr: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
              pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
              pt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
              pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: { xs: '#FFFFFF', sm: '#FFFFFF', md: '#FFFFFF', lg: '#FFFFFF' },
                mt: { xs: 20, sm: 0, md: 20, lg: 15 },
                mb: { xs: 2, sm: 5, md: 2, lg: '13rem' },
                textShadow: { xs: '2px 2px 4px rgba(0,0,0,0.5)', sm: 'none', md: 'none', lg: 'none' }
              }}
            >
              {translations[language]['Scopri le carte']}
            </Typography>



            <Button
              component="a"  // Specify that the Button behaves like an anchor tag
              onClick={handleApriIlConto}
              target="_blank"  // Open the link in a new tab/window
              rel="noopener noreferrer" // Add necessary security attributes when opening in a new tab/window
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                color: '#000000',
                borderRadius: '10px',
                fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' },
                mt: { xs: '20px', sm: 4, md: 1, lg: 5 },
                mb: { xs: 2, sm: 4, md: 1, lg: 5 },
                padding: '10px 30px',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#ffffff' },
              }}
            >
              {translations[language]['APRI IL CONTO']}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Container maxWidth="lg">
          <Box sx={{
            mb: { xs: 1, sm: 1, md: 2, lg: 2 },
            mt: { xs: 4, sm: 6, md: 8, lg: 10 }
          }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: '#ffffff',
                textAlign: 'center',
              }}
            >
              {translations[language]['Apri CRH Money White']}<br />
              {translations[language]['il conto Premium']}<br />
              {translations[language]['a soli 9,90 euro al mese.']}
            </Typography>
          </Box>
        </Container>
      </Box>


      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
          pt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
          pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
        }}>
          <img src="/images/Cardw.webp" alt="Home51" style={{ width: '70%', height: 'auto', transform: 'rotate(-10deg)' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          pr: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
          pt: { xs: '0rem', sm: '3rem', md: '4rem', lg: '5rem' },
          pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
        }}>
          <Box sx={{ width: { xs: '70%', sm: '70%', md: '70%', lg: '95%' }, height: 'auto' }}>
            <picture>
              <source media="(min-width: 1200px)" srcSet={getCards3Image()} />
              <source media="(min-width: 900px)" srcSet={getCards3Image()} />
              <source media="(min-width: 600px)" srcSet={getCards3Image()} />
              <img src={getCards4Image()} alt="Piani" style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }} />
            </picture>
          </Box>
        </Grid>
      </Grid>
     
  {/*    <Box>
        <Container maxWidth="lg">
          <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 }, mt: '2rem' }}>

            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: '#ffffff',
                textAlign: 'center',
              }}
            >

              {translations[language]['Per usurfuire di limiti estesi']}<br />
              {translations[language]['è possibile attivare la CRH Money Blu']}<br />
              {translations[language]['aggiungendo solo 10 euro al mese']}<br />
              {translations[language]['direttamente dall’app.']}
            </Typography>
          </Box>
        </Container>
      </Box>


      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          textAlign: 'center',
          pt: { xs: '0rem', sm: '3rem', md: '4rem', lg: '5rem' },
          pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
        }}>
          <Box sx={{ width: { xs: '100%', sm: '70%', md: '70%', lg: '100%' }, height: 'auto' }}>
            <picture>
              <source media="(min-width: 1200px)" srcSet={getCards5Image()} />
              <source media="(min-width: 900px)" srcSet={getCards5Image()} />
              <source media="(min-width: 600px)" srcSet={getCards5Image()} />
              <img src={getCards6Image()} alt="Piani" style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }} />
            </picture>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          pt: { xs: '0rem', sm: '3rem', md: '4rem', lg: '5rem' },
          pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
        }}>
          <img src="/images/Cardb.webp" alt="Home51" style={{ width: '60%', height: 'auto', transform: 'rotate(-10deg)' }} />
        </Grid>




      </Grid>


      <Box>
        <Container maxWidth="lg">
          <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 }, mt: '2rem' }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: '#ffffff',
                textAlign: 'center',
              }}
            >
              {translations[language]['A soli 19,90 al mese avrai i seguenti servizi:']}
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: { xs: '4rem', sm: '10rem', md: '10rem', lg: '8rem' },

      }}>
        <picture style={{ width: '100%', maxWidth: '1000px' }}>
          <source media="(min-width: 1200px)" srcSet={getCards2Image()} />
          <source media="(min-width: 900px)" srcSet={getCards2Image()} />
          <source media="(min-width: 600px)" srcSet={getCards2Image()} />
          <img src={getCardsImage()} alt="Piani" style={{ width: '100%', height: 'auto', objectFit: 'contain', objectPosition: 'center' }} />
        </picture>
      </Box>

      <Box>
        <Container maxWidth="lg">
          <Box sx={{
            mb: { xs: 2, sm: 3, md: 4, lg: 5 },
            mt: 1
          }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                color: '#ffffff',
                textAlign: 'center',
              }}
            >
              {translations[language]["Non regaliamo punti, non ti diamo premi, non abbiamo canoni zero, perché a differenza degli altri servizi finanziari on line, non usiamo i tuoi soldi, che vengono depositati in conti di salvaguardia regolamentati dalla BCE."]}

            </Typography>
          </Box>
        </Container>
      </Box>

*/}




    </>
  );
};



export default Cards;
