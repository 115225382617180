import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import { useEffect } from 'react';

const About = () => {
  const { language } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          pt: { xs: '180px', sm: '180px', md: '180px', lg: '120px' },
          pb: { xs: '30px', sm: '30px', md: '30px', lg: '30px' },
        }}
      >

        <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }, height: 'auto' }}>
          <picture>
            <img
              src="/images/Chisiamo1.webp"
              alt="Piani"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </picture>
        </Box>


        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: { xs: 4, sm: 6, md: 8, lg: 10 },
          }}
        >
          <Container>
            <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                }}
              >
                {translations[language]['Chi siamo']}
              </Typography>
            </Box>

            <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                  lineHeight: 1.6,
                }}
              >
                {translations[language]["Casa Reale Holding SPA attraverso il calcio e le sue progettualità, ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona."]}<br />
                {translations[language]["L'inclusione finanziaria è il nostro principale impegno, la concessione dell'utilizzo del brand CRH Money esclusivamente nella qualità di copyright, gestita da importanti partnership, ha come obiettivo la realizzazione di un servizio di pagamenti all'avanguardia volto ad accompagnare chi condivide i nostri valori ei nostri traguardi."]}<br />
                {translations[language]["Eccellenza e competenza nel Wealth Management. Investiamo ogni giorno per migliorare il nostro servizio e lavoriamo per rendere il mondo un posto migliore."]}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                  mb: 2,
                  mt: 4, // Add some margin bottom to create space between the link and the end of the box
                }}
              >
                {translations[language]['Per sapere di più, visita il sito:']}
                <Link
                  href="https://www.casarealeholding.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: '#ffffff', textDecoration: 'underline' }}
                >
                  Casa Reale Holding
                </Link>
              </Typography>
            </Box>


          </Container>
        </Box>
      </Box>
    </>
  );
};

export default About;
